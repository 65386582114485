.slick-slider {
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: "768px") {
    margin-left: 0px;
    margin-right: 0px;
  }

  .box {
    background-color: transparent !important;
    @include box-shadow(none !important);
    padding: 0 !important;
    font-size: $font-size-base;
  }
}

.slick-slide {
  height: inherit;

  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: "768px") {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.slick-track {
  display: flex;
}

.slick-vertical {
  height: auto;

  .slick-track {
    display: block;
  }

  .slick-dots {
    right: 50px;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    @include flex-column();
    @include align-items-end();

    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.slick-slide-center {
  opacity: 0.3;
  @include transition(opacity 0.3s);

  &.slick-active {
    opacity: 1;
  }
}

.equal-height {
  &.slick-initialized {
    .slick-slide > div,
    .box {
      height: 100%;
    }
  }
}

.slick-arrows {
  @include d-flex();
  @include justify-content-center();
}

.slick-arrow {
  text-align: center;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }

  @include transform(translateY(-50%));
  color: $black;

  &.slick-disabled {
    cursor: no-drop;
    color: #ccc;
  }

  &.slick-prev {
    left: 0;
    right: auto;
  }

  &.slick-next {
    left: auto;
    right: 0;
  }

  i {
    line-height: inherit;
  }

  cursor: pointer;
}

.arrow-center {
  .slick-arrow {
    i {
      font-size: 36px;
      font-weight: 300;
    }

    &.slick-prev {
      left: -45px;
    }

    &.slick-next {
      right: -45px;
      left: auto;
    }
  }
}

.arrow-top {
  .slick-arrows {
    position: absolute;
    top: 0;
    right: 15px;

    .slick-arrow {
      position: static;

      i {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .slick-next {
      left: auto;
    }
  }
}

.arrows-inner {
  .slick-prev {
    left: 50px !important;
  }

  .slick-next {
    right: 50px !important;
  }
}

.slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  top: auto;
  bottom: 0;
  @include d-flex();
  @include justify-content-center();
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    width: 12px;
    cursor: pointer;
    text-align: center;
    color: currentColor;
    opacity: 0.4;
    padding: 0 15px;
  }

  span {
    position: relative;
  }

  span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    line-height: 12px;
    width: 12px;
    height: 12px;
    background: #ccc;
    border-radius: 50%;
  }
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
  opacity: 1;
}

.slick-dots li.slick-active span:before {
  background: $primary;
}

.slick-slider {
  &.hide-arrow {
    .slick-arrows {
      display: none;
    }
  }

  &.slick-dot-style-01 {
    .slick-dots {
      li {
        opacity: 1;
        color: transparent;

        span:before {
          width: 12px;
          height: 12px;
          display: inline-block;
          border-radius: 50%;
          background-color: $primary;
          border: 2px solid $body-bg;
        }

        &.slick-active span:before {
          width: 11px;
          height: 11px;
          background-color: transparent;
          border: 2px solid $orange;
          color: transparent;
        }
      }
    }
  }
}

.slick-custom-nav {
  .arrow {
    font-size: 20px;
    color: $black;
    cursor: pointer;

    i {
      font-weight: 400;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.disabled {
      color: #cccccc;
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 400px) {
    display: none !important;
  }
}

.dots-inner {
  .slick-dots {
    bottom: 50px;
  }
}
