/*
Template Name: Porus - Bakery Store HTML 5 Template
Theme URI: https://templates.g5plus.net/porus/
Author: G5 Theme
Author URI: https://themeforest.net/user/g5theme
Description: Bakery Store HTML 5 Template With Bootstrap 4 Framework
Version: 1.0.5
Tags: bakery, bakery store, store, food, bootstrap 4, html template
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Initial
# Core Css
# Header
# Footer
# Page title
# Widgets
# Elements
# Pages
# Helper
# Vendors
# Dark
# Responsive
--------------------------------------------------------------*/
@import "./sass/functions";
@import "./sass/variables";
@import "./sass/mixins";
@import "./sass/root";
@import "./sass/reboot";
@import "./sass/type";
@import "./sass/images";
@import "./sass/code";
@import "./sass/grid";
@import "./sass/tables";
@import "./sass/forms";
@import "./sass/buttons";
@import "./sass/transitions";
@import "./sass/dropdown";
@import "./sass/button-group";
@import "./sass/input-group";
@import "./sass/custom-forms";
@import "./sass/nav";
@import "./sass/navbar";
@import "./sass/card";
@import "./sass/breadcrumb";
@import "./sass/pagination";
@import "./sass/badge";
@import "./sass/jumbotron";
@import "./sass/alert";
@import "./sass/progress";
@import "./sass/media";
@import "./sass/list-group";
@import "./sass/close";
@import "./sass/toasts";
@import "./sass/modal";
@import "./sass/tooltip";
@import "./sass/popover";
@import "./sass/carousel";
@import "./sass/spinners";
@import "./sass/utilities";
@import "./sass/print";

/*--------------------------------------------------------------
## Initial
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Core Css
--------------------------------------------------------------*/
@import "./sass/custom-helper";
@import "./sass/typography";
@import "./sass/layout";

/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/
@import "./sass/header";

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
@import "./sass/footer";

/*--------------------------------------------------------------
## Page Title
--------------------------------------------------------------*/

/*--------------------------------------------------------------
## Widgets
--------------------------------------------------------------*/
@import "./sass/widget";
@import "./sass/page-title";

/*--------------------------------------------------------------
## Elements
--------------------------------------------------------------*/
@import "./sass/elements";

/*--------------------------------------------------------------
## Pages
--------------------------------------------------------------*/
@import "./sass/pages";

/*--------------------------------------------------------------
## Vendors
--------------------------------------------------------------*/
@import "./sass/vendors";

/*--------------------------------------------------------------

/*--------------------------------------------------------------
## Elements
--------------------------------------------------------------*/

/* Styling for Slider Arrows */
/* YourComponent.css */

.hero-banner-arrow {
  // width: 100%;
  // margin: auto;

  .slick-arrow {
    color: #000;
    font-size: 0;
    line-height: 1;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    &.slick-disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  .slick-next {
    top: 50%;
    right: 60px;
    left: auto;
    transform: rotate(0deg) translateX(30px); /* Adjust the translateX value as needed */

    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.12061 1.83603L8.14785 7.86328L2.12061 13.8905' stroke='black' stroke-width='3.62942'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 11px;
      height: 16px;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  .slick-prev {
    top: 50%;
    right: 0px;
    left: 60px;
    transform: rotate(0deg) translateX(-30px); /* Adjust the translateX value as needed */

    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.87549 13.89L2.84824 7.86271L8.87549 1.83546' stroke='black' stroke-width='3.62942'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 11px;
      height: 16px;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }
}

.card-text-hide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body {
  overflow: visible !important;
}

@media (max-width: 1200px) {
  .header-position-change {
    position: sticky !important;
  }
}
