@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  font-family: "Nunito", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif !important;
}

.cancel-btn {
  margin-right: 10px !important;
}

.text-end {
  text-align: end !important;
}

::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* sidebar css */
.css-br0tsj {
  background-color: #d1d1d17a !important;
}

.css-1wvake5 {
  border-right: none !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

.css-1rt5bto-MuiTablePagination-selectLabel {
  font-size: 16px !important;
}

.css-1ycxt2a-MuiButtonBase-root-MuiIconButton-root {
  color: #000 !important;
}
/* .ps-menu-button:hover .css-1ycxt2a-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
} */

.css-17w9904-MuiTypography-root {
  font-size: 15px !important;
}

.ps-menu-button {
  color: #000 !important;
  font-weight: 500 !important;
}

.sidebar-items .ps-menu-button:hover {
  background-color: #153e80 !important;
  border-radius: 10px;
  color: #fff !important;
}

.sidebar-items .ps-menu-button-submenu:hover {
  border-radius: 10px;
  color: #fff !important;
background-color: #153e80 !important;
}

.ps-menu-button {
  padding-right: 0px !important;
  padding-left: 20px !important;
}

.ps-menuitem-root.ps-active .ps-menu-button {
  background-color: #153e80 !important;
  border-radius: 10px;
  color: #fff !important;
}

.recordLimit .MuiSelect-icon {
  fill: "#000 !important";
}

.ps-submenu-content.ps-open.css-z5rm24 {
  background: transparent !important;
}

/* .ps-submenu-content {
  background-color: #1f2a40 !important;
} */

.css-z5rm24 {
  background-color: transparent !important;
}

.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
}

.css-16jesut > .ps-menu-button {
  height: 45px !important;
  padding-right: 25px !important;
  padding-left: 20px !important;
  font-size: 0.8571428571428571rem;
}

.css-12vkui9 > .ps-menu-button {
  padding-right: 0 !important;
  padding-left: 0px !important;
  margin: 0 10px;
  margin-right: 0px;
}

.collapse-menu-view a {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.css-1l5fxv3 {
  width: 220px !important;
}

.css-7ms3qr-MuiTablePagination-displayedRows {
  font-size: 16px !important;
}

.css-jzcd7z-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 16px;
}

.close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  color: #000;
}

.cancel-filter {
  padding: 12px 40px;
  width: 50%;
  font-size: 14px;
  border-radius: 10px;
  color: #fff !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.order-filter-button {
  width: 50%;
  padding: 12px 40px;
  font-size: 14px;
  /* border: 1px solid #1f2a40; */
  font-weight: 600 !important;
  border-radius: 10px;
  color: #fff;
}

.css-w86noj {
  min-width: 140px !important;
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: BLACK;
  font-size: 20px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-1y8qrae-MuiButtonBase-root-MuiIconButton-root {
  color: #000;
}

.css-s66mbf-MuiFormLabel-root-MuiInputLabel-root {
  color: #000;
}

.css-1wjqhm-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 8px !important;
  overflow: visible !important;
}

.css-1coo9st-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  border: 1px solid;
}

.css-dnkzfa-MuiSwitch-track {
  background-color: #000 !important;
}

label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px;
}

.product_detail_img img {
  max-width: 450px;
  width: 100%;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .control-dots {
  bottom: 0;
  display: none;
}

.carousel .carousel-status {
  display: none;
}

.css-171t4xp-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #4f46e5 !important;
}

.css-o8jfh7-MuiTabs-indicator {
  background-color: #4f46e5 !important;
}

.css-eklqwb-MuiTablePagination-selectLabel {
  font-size: 14px !important;
  color: #000 !important;
}

.css-1ug8hdj-MuiTablePagination-displayedRows {
  font-size: 14px !important;
  color: #000 !important;
}

.action-button {
  color: #fff;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.default-button {
  color: #000000;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  color: #000000;
  border: 0;
  border-color: #000000;
  padding: 6px 7px 3px 7px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.make-default {
  color: #fff;
  background-color: #676768ee;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.action-button.edit {
  background: #4caf50;
}

.action-button.delete {
  background: rgb(239, 83, 80);
}

.action-button.delete-btn {
  background: rgb(239, 83, 80);
}

.css-1ghvzjo-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #0000003d !important;
}

.css-2y55oh-MuiFormLabel-root-MuiInputLabel-root {
  padding: 3px 3px !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 24px 10px !important;
}
.css-i3a0ch-MuiStepLabel-labelContainer,
.css-y7tcbt-MuiStepLabel-label.Mui-active,
.css-oxf95d-MuiSvgIcon-root-MuiStepIcon-root,
.css-y7tcbt-MuiStepLabel-label.Mui-completed {
  color: #306bcb !important ;
}
.css-1iiaq29-MuiInputAdornment-root {
  color: gray !important;
}
.css-1kk8bgq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #4f46e5 !important;
}

.css-1esy7ky-MuiFormLabel-root-MuiInputLabel-root {
  -webkit-transform: translate(14px, 16px) scale(1);
  -moz-transform: translate(14px, 16px) scale(1);
  -ms-transform: translate(14px, 16px) scale(1);
  transform: translate(14px, 17px) scale(1) !important;
}

button.add_button {
  height: 40px;
  width: 40px;
  background: rgb(79, 70, 229);
  color: #fff;
  border: 0;
  font-size: 25px;
  border-radius: 7px;
  margin-left: 18px;
}

button.adddelete {
  height: 40px;
  width: 40px;
  background: red;
  color: #fff;
  border: 0;
  font-size: 25px;
  border-radius: 7px;
  margin-left: 18px;
  line-height: 0;
}

.css-w2m9yq-MuiButtonBase-root-MuiButton-root {
  font-weight: 600 !important;
}

.css-q9325e-MuiInputBase-root-MuiTablePagination-select {
  color: #000 !important;
}

.css-67d4hh-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: black !important;
}
.css-1mbgxj6-MuiFormControl-root-MuiTextField-root
  .MuiInputLabel-root.Mui-error {
  color: rgb(92 91 91 / 100%) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.css-1mbgxj6-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

@media (min-width: 400px) {
  .modelBoxx {
    left: 0px !important;
  }
}

.rdw-editor-main {
  height: "40%";
}



/* dashboard css  */
/* .company-details .css-e9xbrs-MuiPaper-root-MuiCard-root {
  background-color: #d1d1d17a !important;
} */


/* header css  */
.header-main .css-1k0i2s9-MuiPaper-root-MuiAppBar-root {
  background-color: #d1d1d17a !important;
  color: #000 !important;
}

 /* mui datatable css  */
/* .mui-table .css-8er80j-MuiPaper-root-MuiTableContainer-root {
  background-color: #fff !important;
  color: #000 !important;
} */

.css-1qxadfk-MuiPaper-root-MuiDialog-paper {
  background-color: #fff !important;
}

/* table checkbox  */
.mui-table-checkbox {
  color: #000 !important;
}


/* autocomplete css */
/* .css-1s5lphu-MuiPopper-root-MuiAutocomplete-popper .css-a46fqw-MuiPaper-root-MuiAutocomplete-paper {
  background-color: #d1d1d1;
  color: #000 !important;
}
.css-1dylv9m-MuiFormControl-root-MuiTextField-root .css-16y2wkt-MuiFormLabel-root-MuiInputLabel-root {
  color: #000 !important;
} */

/* datatable css  */
.css-ltuyj6:hover {
  color: #000 !important;
}

/* dialogbox css  */
.css-1wc28bj {
  color: #000 !important;
}
.css-vps42uj {
  color: #000 !important;
}
.css-1o80nbi {
  color: #000 !important;
}
.css-178btci {
  /* border-bottom: 1px solid #000 !important; */
}
.css-adx198 {
  color: #000 !important;
}


 /* common selectbox dropdown css  */
 .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #fff !important;
  color: #000 !important;
 }


/* data table css  */
.css-ltuyj6.Mui-active {
  color: #000 !important;
}

/* grid-permit-location css */
.grid-permit-location .css-1wzacjw-MuiPaper-root {
  background-color: #fff !important;
}