.css-1whef33-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  /* color: transparent !important; */
}
.css-1whef33-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: black !important;
}

.css-1whef33-MuiButtonBase-root-MuiTableSortLabel-root {
  color: black !important;
}

/* .css-qshjlw-MuiPaper-root {
  background-color: white !important;
  color: black !important;
} */

/* 0e2344  */

/* .css-1k56taf-MuiButtonBase-root-MuiIconButton-root {
  background-color: white !important;
  border-radius: 15% !important ;
  margin-right: 10px !important;
  color: black !important;
} */

.tablerow {
  text-align: left !important;
  padding-left: 40px;
}

.css-1whef33-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  opacity: 1 !important;
  color: black !important;
}

.css-1f9ifje-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}

.css-jowbcf-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid black !important;
}
.css-u7w8os-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}

