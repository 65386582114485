.gm-style-iw {
	padding: 0 !important;

	button {
		display: none !important;
	}
}

.gm-style .gm-style-iw-d {
	overflow: visible !important;
}

.gm-style .gm-style-iw-c {
	border-radius: 0;
}