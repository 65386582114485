.page-shop {
	.page-content-inner {
		padding-right: 85px;
		@include media-breakpoint-down(lg) {
			padding-right: 0;
		}
	}

	.table {
		margin-bottom: 0;

		thead th {
			border-top: 0;
		}
	}

	.price {
		span {
			font-weight: 400;
		}

		.old-price {
			color: $black;
			text-decoration: line-through;
			display: inline-block;
			margin-left: 3px;
		}

		.current-price {
			color: $black;
		}

	}
}

.shop-checkout {
	.wrapper-content {
		padding-bottom: 160px !important;
	}

	.alert {
		background: #f6f6f6;
		margin-bottom: 58px;
	}

	.page-container {
		.form-control {
			border-radius: 0;
		}
	}

	textarea {
		height: 142px;
	}

	.arrows {
		&::after, &:before {
			right: 19px;
		}

		&::before {
			top: 8px;
		}

		&::after {
			bottom: 8px;
		}
	}


	.card {
		background: #f6f6f6;
		padding: 35px;

	}


}

.sidebar-checkout {
	.custom-radio {
		line-height: 1.7;

		label {
			&::before {
				border-color: $black;
				top: 0.25em;
				background-color: transparent;
			}
		}

	}
}

.shop-listing {
	.wrapper-content {
		padding-bottom: 140px !important;
	}

	.page-title {
		background-image: url("../../../../assets/image/shop/larger-shop.jpg");
	}

	select {
		background-position: calc(100% - 19px) calc(23px), calc(100% - 14px) calc(23px), 100% 0;
	}

	.filter {
		.form-group {
			@include flex(0 0 270px);
			max-width: 270px;
			margin-bottom: 0;
			margin-top: -14px;
			@include media-breakpoint-down(sm) {
				margin-top: 0;
			}

			.select-custom {
				&::after {
					right: 0;
				}
			}

			select {

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
}

.shop-sidebar {
	.form-control {
		border-radius: 0;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom-color: #000;
		color: $gray-600;
		font-weight: 500;
		box-shadow: none;

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	.ui-slider-horizontal {
		height: 1px;
		margin-left: 0;
	}

	.ui-widget-content {
		background: $black;

		.ui-widget-header {
			background: $black;
		}
	}

	.ui-slider .ui-slider-handle {
		width: 13px;
		height: 13px;
		background-color: $black;
		border-radius: 50%;
		margin-left: 0;
	}
}

.product {
	.image-content {
		right: 13px;
		top: 0;
	}

	.badge {
		padding-left: 8.5px;
		padding-right: 8.5px;

		&.badge-success {
			background: #83b900;
		}
	}

	.author-rate {
		margin-bottom: 10px;

		.icon {
			width: 16px;
			height: 16px;
			margin: 0 -2px;
		}

		.rate-item {
			margin: 0 2px 0 0;
		}

		.checked {
			.icon {
				fill: #f2b309;
				stroke: #f2b309;
			}
		}

	}

	.add-to-cart {
		top: -25px;
		left: 50%;
		transform: translateX(-50%);
		@include transition(.5s ease);
	}

	.product-meta-wrapper {
		height: 53px;
		overflow: hidden;
	}

	.product-meta {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		@include transition(.5s ease);
	}

	&:hover {
		.add-to-cart {
			top: 5px;
		}

		.product-meta {
			top: 53px;
		}
	}
}

.my-account {
	.tabs {
		.nav-item {
			padding: 0 28px;
		}
	}

	.table {

		th {
			text-transform: uppercase;
			color: $black;
		}

		th, td {
			padding-left: 24px;
			padding-right: 24px;
		}

		td {
			color: $gray-600;
			padding-top: 27px;
			padding-bottom: 27px;
		}

		.btn {
			border-color: #bfbfbf;
		}
	}


	.status {
		&.pending {
			color: $link-color;
		}

		&.cancel {
			color: #cc0000;
		}
	}


}

.account-sidebar {
	.card {
		.info-item {
			.label {
				@include flex(0 0 35%);
				max-width: 35%;
			}

			.value {
				@include flex(0 0 65%);
				max-width: 65%;
			}
		}
	}
}

.my-product {

	.product-image {
		@include flex(0 0 62px);
		height: 61px;
		max-width: 62px;
	}

	.price {
		color: $black;
	}

	@include media-breakpoint-down(md) {
		@include flex-wrap();
		.product-image {
			@include flex(1 1 100%);
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

.shopping-cart {
	.wrapper-content {
		padding-bottom: 170px !important;
		padding-top: 107px !important;
	}

	.table {
		tr:first-child {
			td {
				border-top: 0;
			}
		}

		td {
			font-size: 16px;
			padding-top: 37px;
			padding-bottom: 40px;
			padding-left: 0;
			color: $black;
		}
	}

	.media {
		.image {
			width: 100px;
		}
	}

	.quality {
		border: 1px solid $border-color;
		width: 128px;
		padding: 0 10px;

		label {
			margin-bottom: 0;
			color: $gray-600;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		.form-control {
			border: none;
			text-align: right;
			width: 40%;
			-moz-appearance: textfield;

			&:focus {
				box-shadow: none;
				background: transparent;
			}
		}
	}

	.input-group {
		.input-group-prepend {
			font-size: 18px;
			color: $black;
			margin-right: 5px;
			font-weight: 700;

			i {
				font-weight: 400;
			}
		}

		.form-control {
			border: none;
			font-size: 14px;

			&:focus {
				@include box-shadow(none);
				background: transparent;
			}
		}

		.btn {
			font-size: 14px;
		}
	}

}

.cart-sidebar {
	.card {
		background: #f6f6f6;
	}

	.custom-radio {
		color: $black;

		label {
			color: $black;
			margin-bottom: 0;
			margin-left: 8px;

			&::before {
				border-color: $black;
				left: -25px;
				width: 12px;
				height: 12px;
				top: 50%;
				transform: translateY(-50%);
				background: transparent;
			}
		}


		.custom-control-label::after {
			width: 6px;
			height: 6px;
			border-radius: 50%;
			top: 11px;
			left: -21.5px;
		}
	}

	.express {
		.custom-control-label {
			color: $gray-600;

			&::before {
				border-color: $gray-600;
			}
		}

		.custom-control-input:checked ~ .custom-control-label {
			color: $black;

			&::before {
				border-color: $black;
			}
		}
	}


	.btn {
		font-size: 18px;
		border-radius: 0;
	}

}

.page-single-product {
	.page-title {
		background: #f3f3f3;

		.breadcrumb-item:last-child {
			color: $gray-600;
		}
	}

	.product-images {
		padding-right: 100px;
		@include media-breakpoint-down(lg) {
			padding-right: 0;
		}
		@include media-breakpoint-down(md) {
			@include flex-wrap();
			padding-right: 0;
		}


		.slider-nav {
			@include flex(0 0 70px);
			max-width: 70px;
			margin: 0;
			order: 1;

			.slick-slide {
				padding: 10px 0;
			}

			.slick-current {
				.box {
					border: 1px solid $gray-600;
				}

			}

			@include media-breakpoint-down(md) {
				@include flex(0 0 100%);
				max-width: 100%;
				order: 2;
			}
		}

		.slider-for {
			@include flex(1 1 auto);
			order: 2;


			.image {
				position: relative;
			}

			.image-icon {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 36px;
				font-weight: 300;

			}

			@include media-breakpoint-down(md) {
				@include flex(0 0 100%);
				max-width: 100%;
				order: 1;
			}
		}
	}

	.product-details {
		.form-add-cart {
			.input-group {
				@include flex(0 0 114px);
				border: 1px solid $border-color;
				@include media-breakpoint-down(xs) {
					@include flex(1 1 100%);
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 15px;
				}
			}

			.form-control {
				&:focus {
					background: none;
					box-shadow: none;
				}
			}

			.input-group-prepend, .input-group-append {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
			}

			.input-group-prepend {
				color: #cccccc;
				left: 16px;
			}

			.input-group-append {
				color: $black;
				right: 16px;

			}

			.button-add-cart {
				@include flex(1 1 auto);
			}
		}

		.social-icon {
			li:not(:last-child) {
				margin-right: 26px;
			}

			a {
				color: #ccc;
			}

			a:hover {
				color: $black;
			}
		}
	}

	.relate-products {
		.slick-arrow {
			i {
				font-size: 30px;
				font-weight: 300;

			}
		}

		.slick-prev {
			left: -50px;
		}

		.slick-next {
			right: -50px;
		}
	}
}

