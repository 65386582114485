//----------------------------------------------------------------------
// Typography style
//----------------------------------------------------------------------

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, $black 50%), linear-gradient(135deg, $black 50%, transparent 50%);
	background-position: calc(100% - 19px) calc(18px), calc(100% - 14px) calc(18px), 100% 0px;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;
	border: none;
	border-bottom: 1px dashed;
	border-color: $black;
	width: 100%;
}

select::-ms-expand {
	display: none;
}


.select-custom, .arrows {
	position: relative;
	width: 100%;

	select {
		background-image: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding-right: 30px !important;
		position: relative;
		z-index: 10;
	}

	&::after, &::before {
		font-family: "Font Awesome 5 Pro";
		font-size: 14px;
		line-height: 2;
		position: absolute;
		right: 12px;
		color: $secondary;
		z-index: 9;
	}
}

.select-custom {
	&::after {
		content: '\f078';
		font-size: $font-size-base;
		line-height: 2;
		top: 50%;
		transform: translateY(-50%);
	}
}

.arrows {
	&::before {
		content: "\f077";
		top: 4px;
	}

	&::after {
		content: '\f078';
		bottom: 4px;
	}
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	background-color: transparent;
	content: '\f00c';
	font-family: 'Font Awesome 5 Pro';
	color: $black;
	top: 0;
	font-size: 16px;
}

.custom-control-input:checked ~ .custom-control-label::before {
	background: transparent;
	color: $black;
	border-color: $black;
}


.custom-radio {
	.custom-control-input:checked ~ .custom-control-label::before {
		background: transparent;
		border-color: $black;
	}

	.custom-control-input:checked ~ .custom-control-label::after {
		background-image: none;
		background: $black;
	}

	.custom-control-label::after {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		top: 0.5rem;
		left: -20px;
	}
}

svg {
	&.icon {
		display: inline-block;
		width: 1em;
		height: 1em;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
	}
}

.bs-tooltip-top {
	opacity: 1 !important;

	.tooltip-arrow {
		&::before {
			border-width: 20px 20px 0;
		}
	}

	.arrow {
		left: 50% !important;
		transform: translateX(-50%);
	}
}

.bs-tooltip-top {
	top: -9px !important;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
	border-width: 8px 3px 0;
}

iframe {
	border: 0;
}




