// Determine whether to use dark or light text on top of given color.
// Returns black for dark text and white for light text.
$translate_height: 50px;
@function contrast-color($color, $lightColor: #fff, $darkColor: #222) {
	$lightness: lightness($color);

	@if ($lightness < 75) {
		@return $lightColor;
	} @else {
		@return $darkColor;
	}
}
@function adjust-brightness-color($color, $adjust: 20%) {
	$lightness: lightness($color);

	@if ($lightness > 25) {
		@return darken($color, $adjust);
	} @else {
		@return lighten($color, $adjust);
	}
}

@mixin media-xxs() {
	@media only screen and (max-width: $small_mobile_width) {
		@content;
	}
}

@mixin media-xs() {
	@media only screen and (max-width: $mobile_width - 1px) {
		@content;
	}
}

@mixin media-sm() {
	@media only screen and (max-width: $tablet_width - 1px) {
		@content;
	}
}

@mixin media-md() {
	@media only screen and (max-width: $desktop_width - 1px) {
		@content;
	}
}

@mixin media-lg() {
	@media only screen and (max-width: $wide_width - 1px) {
		@content;
	}
}

@mixin media-xl() {
	@media only screen and (min-width: $wide_width) {
		@content;
	}
}

@mixin selection() {
	::-moz-selection {
		@content;
	}
	::selection {
		@content;
	}
}
@mixin transition($pro...) {
	-webkit-transition: $pro;
	transition: $pro;
}

@mixin transition-delay($pro) {
	-webkit-transition-delay: $pro;
	transition-delay: $pro;
}

@mixin transition-duration($pro...) {
	-webkit-transition-duration: $pro;
	transition-duration: $pro;
}

@mixin transition-property($pro...) {
	-webkit-transition-property: $pro;
	transition-property: $pro;
}

@mixin transition-timing-function($pro...) {
	-webkit-transition-timing-function: $pro;
	transition-timing-function: $pro;
}

@mixin transform($pro...) {
	-webkit-transform: $pro;
	transform: $pro;
}

@mixin transform-origin($x:center, $y:center) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

@mixin transform-style($pro...) {
	-webkit-transform-style: $pro;
	transform-style: $pro;
}

@mixin perspective($value: 1000) {
	-webkit-perspective: $value;
	perspective: $value;
}

@mixin push--auto {
	margin: {
		left: auto;
		right: auto;
	}
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote(( $y / $x ) * 100 + '%');
	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
	@include pseudo($pos: $position);
	width: 0;
	height: 0;
	@if $round {
		@include border-radius(3px);
	}
	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round( $size / 2.5 );
	} @else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round( $size / 2.5 );
	} @else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	} @else if $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}

@mixin input-placeholder {
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}

@mixin truncate($truncation-boundary) {
	max-width: $truncation-boundary;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Need to trigger hardware acceleration for some animation,
// keeping everything fast, slick and flicker-free.
@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}
	perspective: $perspective;
}


// Mixin for display flex
//========================================================
@mixin d-flex($important: '') {
	@if ($important == important) {
		display: -ms-flexbox !important;
		display: flex !important;
	} @else {
		display: -ms-flexbox;
		display: flex;
	}
}

@mixin d-inline-flex($important: '') {
	@if ($important == important) {
		display: -webkit-inline-box !important;
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	} @else {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
}

@mixin flex-row($important: '') {
	@if ($important == important) {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	} @else {
		-ms-flex-direction: row;
		flex-direction: row;
	}
}

@mixin flex-row-reverse($important: '') {
	@if ($important == important) {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	} @else {
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}
}

@mixin flex-column($important: '') {
	@if ($important == important) {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	} @else {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@mixin flex-column-reverse($important: '') {
	@if ($important == important) {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	} @else {
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
}

@mixin flex-wrap($important: '') {
	@if ($important == important) {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	} @else {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@mixin flex-nowrap($important: '') {
	@if ($important == important) {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	} @else {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
}

@mixin flex-wrap-reverse($important: '') {
	@if ($important == important) {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	} @else {
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}
}

@mixin flex-fill($important: '') {
	@if ($important == important) {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	} @else {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}

@mixin flex($pro, $important: '') {
	@if ($important == important) {
		-ms-flex: $pro !important;
		flex: $pro !important;
	} @else {
		-ms-flex: $pro;
		flex: $pro;
	}
}

@mixin flex-grow-0($important: '') {
	@if ($important == important) {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	} @else {
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
}

@mixin flex-grow-1($important: '') {
	@if ($important == important) {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	} @else {
		-ms-flex-positive: 1;
		flex-grow: 1;
	}
}

@mixin flex-shrink-0($important: '') {
	@if ($important == important) {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	} @else {
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
}

@mixin flex-shrink-1($important: '') {
	@if ($important == important) {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	} @else {
		-ms-flex-negative: 1;
		flex-shrink: 1;
	}
}

@mixin justify-content-start($important: '') {
	@if ($important == important) {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	} @else {
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
}

@mixin justify-content-end($important: '') {
	@if ($important == important) {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	} @else {
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

@mixin justify-content-center($important: '') {
	@if ($important == important) {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	} @else {
		-ms-flex-pack: center;
		justify-content: center;
	}
}

@mixin justify-content-between($important: '') {
	@if ($important == important) {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	} @else {
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

@mixin justify-content-around($important: '') {
	@if ($important == important) {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	} @else {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}
}

@mixin align-items-start($important: '') {
	@if ($important == important) {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	} @else {
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

@mixin align-items-end($important: '') {
	@if ($important == important) {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	} @else {
		-ms-flex-align: end;
		align-items: flex-end;
	}
}

@mixin align-items-center($important: '') {
	@if ($important == important) {
		-ms-flex-align: center !important;
		align-items: center !important;
	} @else {
		-ms-flex-align: center;
		align-items: center;
	}
}

@mixin align-items-baseline($important: '') {
	@if ($important == important) {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	} @else {
		-ms-flex-align: baseline;
		align-items: baseline;
	}
}

@mixin align-items-stretch($important: '') {
	@if ($important == important) {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	} @else {
		-ms-flex-align: stretch;
		align-items: stretch;
	}
}

@mixin align-content-start($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	} @else {
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}
}

@mixin align-content-end($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	} @else {
		-ms-flex-line-pack: end;
		align-content: flex-end;
	}
}

@mixin align-content-center($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	} @else {
		-ms-flex-line-pack: center;
		align-content: center;
	}
}

@mixin align-content-between($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	} @else {
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}
}

@mixin align-content-around($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	} @else {
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}
}

@mixin align-content-stretch($important: '') {
	@if ($important == important) {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	} @else {
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}
}

@mixin align-self-auto($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	} @else {
		-ms-flex-item-align: auto;
		align-self: auto;
	}
}

@mixin align-self-start($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	} @else {
		-ms-flex-item-align: start;
		align-self: flex-start;
	}
}

@mixin align-self-end($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	} @else {
		-ms-flex-item-align: end;
		align-self: flex-end;
	}
}

@mixin align-self-center($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	} @else {
		-ms-flex-item-align: center;
		align-self: center;
	}
}

@mixin align-self-baseline($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	} @else {
		-ms-flex-item-align: baseline;
		align-self: baseline;
	}
}

@mixin align-self-stretch($important: '') {
	@if ($important == important) {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	} @else {
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}
}

@mixin flex-flow-wrap-reverse($important: '') {
	@if ($important == important) {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-flow: wrap-reverse !important;
		flex-flow: wrap-reverse !important;
	} @else {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-flow: wrap-reverse;
		flex-flow: wrap-reverse;
	}
}

@mixin flex-flow-wrap($important: '') {
	@if ($important == important) {
		-ms-flex-flow: wrap !important;
		flex-flow: wrap !important;
	} @else {
		-ms-flex-flow: wrap;
		flex-flow: wrap;
	}
}

@mixin flex-flow-column($important: '') {
	@if ($important == important) {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		-ms-flex-flow: column !important;
		flex-flow: column !important;
	} @else {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-flow: column;
		flex-flow: column;
	}
}

@mixin flex-flow-column-reverse($important: '') {
	@if ($important == important) {
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-flow: column-reverse !important;
		flex-flow: column-reverse !important;
	} @else {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-flow: column-reverse;
		flex-flow: column-reverse;
	}
}

@mixin flex-flow-row($important: '') {
	@if ($important == important) {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-flow: row !important;
		flex-flow: row !important;
	} @else {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row;
		flex-flow: row;
	}
}

@mixin flex-flow-row-reverse($important: '') {
	@if ($important == important) {
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: reverse !important;
		-ms-flex-flow: row-reverse !important;
		flex-flow: row-reverse !important;
	} @else {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-flow: row-reverse;
		flex-flow: row-reverse;
	}
}

@mixin clearfix() {
	content: '';
	display: block;
	clear: bottom;
}

@mixin backface-visibility($pro...) {
	-webkit-backface-visibility: $pro;
	backface-visibility: $pro;
}


@mixin keyframes($keyframe_name) {
	@keyframes #{$keyframe_name}  {
		@content;
	}
}

@mixin animation-duration($pro...) {
	-webkit-animation-duration: $pro;
	-moz-animation-duration: $pro;
	-o-animation-duration: $pro;
	animation-duration: $pro;
}

@mixin animation-fill-mode($pro...) {
	-webkit-animation-fill-mode: $pro;
	-moz-animation-fill-mode: $pro;
	-o-animation-fill-mode: $pro;
	animation-fill-mode: $pro;
}

@mixin animation-name($name...) {
	-webkit-animation-name: $name;
	-moz-animation-name: $name;
	-o-animation-name: $name;
	animation-name: $name;
}