
.pb-42 {
	padding-bottom: 42px !important;
}

.pb-75 {
	padding-bottom: 75px !important;
}


.pb-85 {
	padding-bottom: 85px !important;
}

.pt-85 {
	padding-top: 85px !important;
}

.ml-35px {
	margin-left: 35px;
}


.bg-pattern-01 {
	background-image: url("../../../assets/image/other/background-thedir-cover.jpg");
	background-size: cover;
	background-position: center;
}