.div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.img {
  aspect-ratio: 3.13;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .img {
    max-width: 100%;
  }
}
.div-2 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 50px 60px 0;
}
@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-3 {
  display: flex;
  margin-top: 50px;
  width: 100%;
  max-width: 1360px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-3 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-4 {
}
@media (max-width: 991px) {
  .div-4 {
    max-width: 100%;
  }
}
.div-5 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-5 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 47%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column {
    width: 100%;
  }
}
.div-6 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
@media (max-width: 991px) {
  .div-6 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.img-2 {
  inset: 0;
  width: 100%;
}
.img-3 {
  aspect-ratio: 1.37;
  object-fit: contain;
  object-position: center;
  width: 100%;
  fill: rgba(11, 63, 119, 0.6);
  overflow: hidden;
}
@media (max-width: 991px) {
  .img-3 {
    max-width: 100%;
  }
}
.column-2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 53%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-2 {
    width: 100%;
  }
}
.span {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
@media (max-width: 991px) {
  .span {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-7 {
  color: #fe7678;
  font: 700 18px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-7 {
    max-width: 100%;
  }
}
.div-8 {
  color: #000;
  margin-top: 25px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-8 {
    max-width: 100%;
  }
}
.div-9 {
  color: #8b8585;
  margin-top: 32px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-9 {
    max-width: 100%;
  }
}
.div-10 {
  background-color: #dedede;
  margin-top: 99px;
  height: 1px;
}
@media (max-width: 991px) {
  .div-10 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-11 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
}
@media (max-width: 991px) {
  .div-11 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.span-2 {
  display: flex;
  width: 100%;
  max-width: 1360px;
  flex-direction: column;
  margin: 55px 0 50px;
}
@media (max-width: 991px) {
  .span-2 {
    max-width: 100%;
    margin: 40px 0;
  }
}
.div-12 {
  color: #fe7678;
  text-align: center;
  align-self: center;
  font: 700 18px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-13 {
  color: #000;
  text-align: center;
  align-self: center;
  margin-top: 25px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-13 {
    max-width: 100%;
  }
}
.div-14 {
  margin-top: 55px;
}
@media (max-width: 991px) {
  .div-14 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-15 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-15 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-3 {
    width: 100%;
  }
}
.span-3 {
  border-radius: 20px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 20px 35px;
}
.span-3:hover {
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 991px) {
  .span-3 {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.div-16 {
  color: #000;
  font: 700 26px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-17 {
  color: #8b8585;
  margin: 35px 0 19px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.column-4 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-4 {
    width: 100%;
  }
}
.span-4 {
  border-radius: 20px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 49px 35px;
}
@media (max-width: 991px) {
  .span-4 {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.div-18 {
  color: #000;
  font: 700 26px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-19 {
  color: #8b8585;
  margin: 30px 0 46px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-19 {
    margin-bottom: 40px;
  }
}
.column-5 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-5 {
    width: 100%;
  }
}
.span-5 {
  border-radius: 20px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 47px 35px;
}
@media (max-width: 991px) {
  .span-5 {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.div-20 {
  color: #000;
  font: 700 26px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-21 {
  color: #8b8585;
  margin-top: 30px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-22 {
  background-color: #dedede;
  align-self: center;
  width: 1360px;
  max-width: 100%;
  height: 1px;
}
.div-23 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 50px 60px 0;
}
@media (max-width: 991px) {
  .div-23 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-24 {
  display: flex;
  margin-top: 16px;
  width: 100%;
  max-width: 1360px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-24 {
    max-width: 100%;
  }
}
.div-25 {
}
@media (max-width: 991px) {
  .div-25 {
    max-width: 100%;
  }
}
.div-26 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .div-26 {
    align-items: stretch;
    gap: 0px;
  }
}
.column-6 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 58%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-6 {
    width: 100%;
  }
}
.div-27 {
  color: #fe7678;
  font: 700 18px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-27 {
    max-width: 100%;
  }
}
.div-28 {
  color: #000;
  margin-top: 25px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-28 {
    max-width: 100%;
  }
}
.div-29 {
  color: #8b8585;
  margin-top: 31px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-29 {
    max-width: 100%;
  }
}
.column-7 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 42%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-7 {
    width: 100%;
  }
}
.img-4 {
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-4 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-30 {
  background-color: #dedede;
  margin-top: 65px;
  height: 1px;
}
@media (max-width: 991px) {
  .div-30 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-31 {
  background-color: #fff;
  align-self: stretch;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 50px 60px 0;
}
@media (max-width: 991px) {
  .div-31 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-32 {
  margin-top: 43px;
  width: 100%;
  max-width: 1360px;
}
@media (max-width: 991px) {
  .div-32 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-33 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-33 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-8 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 64%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-8 {
    width: 100%;
  }
}
.div-34 {
  flex-grow: 1;
}
@media (max-width: 991px) {
  .div-34 {
    max-width: 100%;
    padding-right: 20px;
  }
}
.div-35 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-35 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-9 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 71%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-9 {
    width: 100%;
  }
}
.div-36 {
  background-color: #dedede;
  margin-top: 529px;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-36 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.column-10 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 29%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-10 {
    width: 100%;
  }
}
.img-5 {
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-5 {
    max-width: 100%;
  }
}
.column-11 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 36%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-11 {
    width: 100%;
  }
}
.span-6 {
  display: flex;
  margin-top: 17px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .span-6 {
    max-width: 100%;
  }
}
.div-37 {
  color: #fe7678;
  font: 700 18px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-37 {
    max-width: 100%;
  }
}
.div-38 {
  color: #000;
  margin-top: 25px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-38 {
    max-width: 100%;
  }
}
.div-39 {
  color: #8b8585;
  margin-top: 46px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-39 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-40 {
  background-color: #f9f9f9;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
}
@media (max-width: 991px) {
  .div-40 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-41 {
  /* display: flex; */
  width: 100%;
  max-width: 1360px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0;
}
@media (max-width: 991px) {
  .div-41 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.span-7 {
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
}
.div-42 {
  color: #fe7678;
  white-space: nowrap;
  font: 700 18px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-42 {
    white-space: initial;
  }
}
.div-43 {
  color: #000;
  margin-top: 25px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-44 {
  border-radius: 20px;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 31px 0;
  width: 210px;
}
.div-44:hover {
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
}
.img-6 {
  aspect-ratio: 3.09;
  max-width: 210px !important;
  object-fit: contain !important;
  object-position: center !important;
  overflow: hidden !important;
}
.img-7 {
  aspect-ratio: 3.09;
  max-width: 210px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.img-8 {
  aspect-ratio: 3.09;
  max-width: 210px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.img-9 {
  aspect-ratio: 3.09;
  max-width: 210px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.img-10 {
  aspect-ratio: 3.09;
  max-width: 210px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.div-45 {
  border-radius: 40px;
  background-color: #ff5a5f;
  align-self: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1360px;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .div-45 {
    max-width: 100%;
    margin-top: 40px;
    padding: 25px;
    border-radius: 0px;
  }
}
.div-46 {
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 100px !important;
  width: 100% !important;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 50px 60px;
}
.div-46:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 991px) {
  .div-46 {
    max-width: 100% !important;
    flex-wrap: wrap !important;
    padding: 0 20px !important;
  }
}
.img-11 {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.span-8 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
@media (max-width: 991px) {
  .span-8 {
    max-width: 100%;
  }
}
.div-47 {
  color: #fff;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-47 {
    max-width: 100%;
  }
}
.div-48 {
  color: #fff;
  margin-top: 32px;
  font: 400 20px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-48 {
    max-width: 100%;
  }
}
.span-9 {
  position: relative;
  color: #fe7678;
  white-space: nowrap;
  border-radius: 14px;
  background-color: #fff;
  justify-content: center;
  margin: auto 0;
  padding: 30px 60px;
  font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica, sans-serif;
  cursor: pointer;
}
@media (max-width: 991px) {
  .span-9 {
    white-space: initial;
    padding: 0 24px;
    font-size: 20px;
  }
}
.img-12 {
  aspect-ratio: 4.51;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 66px;
}
@media (max-width: 991px) {
  .img-12 {
    max-width: 100%;
    margin-top: 40px;
  }
}
