.listing-table {
	td {
		padding: 22px !important;
		vertical-align: middle;

		//.media-body {
		//	flex: 0 0 auto;
		//}
	}

	tr:first-child {
		td {
			border-top: 0;
		}
	}

	tr:last-child {
		td {
			border-bottom: 0;
		}
	}


	.name {
		line-height: 1.2;
	}

	.image {
		width: 54px;
		min-width: 54px;
		max-width: 54px;
		max-height: 54px;
		min-height: 54px;
		height: 54px;
	}
}

.listing-table-job {
	td {
		text-align: left;
	}

	.image {
		width: 57px;
	}
}

.feature {
	.card-body {
		padding: 23px 26px;
	}
}

.company-feature {
	.image {
		position: relative;
	}

	.small-image {
		position: absolute;
		left: 50%;
		bottom: -50px;
		transform: translateX(-50%);
	}

	a {
		line-height: 1.875;
	}

	i {
		font-size: 14px;
		margin-left: 8px;
	}

	.card-title {
		line-height: 1.3;
	}
}

.listing-job-feature {
	.slick-arrows {
		top: -55px !important;
	}

	.contact {
		color: $secondary;
		margin-top: 30px;

		a {
			color: $link-color;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}