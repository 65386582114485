.mfp-bg {
	z-index: 999999 !important;
}
.mfp-popup {
	background-color: #fff;
	max-width: 600px;
	margin: auto;
	padding: 20px;
}

button.mfp-close {
	font-family: 'Font Awesome 5 Pro';
	font-size: 3rem;
	width: 64px;
	height: 64px;
	line-height: 64px;
	&:hover,
	&:focus,
	&:active {
		background-color: transparent !important;
		border-color: transparent !important;
		opacity: 1;
	}
}

button.mfp-arrow {
	&:hover,
	&:focus,
	&:active {
		background-color: transparent !important;
		border-color: transparent !important;
	}
}

/**
* Zoom effect
* ----------------------------------------------------------------------------
*/
.mfp-zoom-in {
	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.2s ease-in-out);
		@include transform(scale(0.8));
	}

	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.3s ease-out);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(scale(1));
		}
		&.mfp-bg {
			opacity: 0.6;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(scale(0.8));
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}

	}

}

/**
* Newspaper effect
* ----------------------------------------------------------------------------
*/
.mfp-newspaper {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.5s);
		@include transform(scale(0) rotate(500deg));
	}

	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.5s);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(scale(1) rotate(0deg));
		}
		&.mfp-bg {
			opacity: 0.95;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(scale(0) rotate(500deg));
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}

	}

}

/**
* Move-horizontal effect
* ----------------------------------------------------------------------------
*/
.mfp-move-horizontal {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.3s);
		@include transform(translateX(-50px));
	}

	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.3s);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(translateX(0));
		}
		&.mfp-bg {
			opacity: 0.95;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(translateX(50px));
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}

	}

}

/**
* Move-from-top effect
* ----------------------------------------------------------------------------
*/
.mfp-align-top {
	.mfp-content {
		vertical-align: top;
	}
}
.mfp-move-from-top {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.5s);
		@include transform(translateY(-100px));
	}

	.mfp-close,
	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.5s);
		@include transition-delay(0s);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(translateY(0));
			@include transition-delay(0.5s);
		}

		&.mfp-bg {
			opacity: 0.95;
		}

		&.gsf-menu-popup {
			opacity: 1;
		}

		.mfp-close {
			opacity: 1;
			font-weight: 400;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(translateY(-50px));
			opacity: 0;
			@include transition-delay(0s);
		}

		.mfp-close,
		&.mfp-bg {
			opacity: 0;
			@include transition-delay(0.5s);
		}

	}

}

/**
* 3d unfold
* ----------------------------------------------------------------------------
*/
.mfp-3d-unfold {

	.mfp-content {
		@include perspective(2000px);
	}

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.3s ease-in-out);
		@include transform-style(preserve-3d);
		@include transform(rotateY(-60deg));
	}

	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.5s);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(rotateY(0deg));
		}
		&.mfp-bg {
			opacity: 0.95;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(rotateY(60deg));
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}

	}

}

/**
* Zoom-out effect
* ----------------------------------------------------------------------------
*/
.mfp-zoom-out {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		@include transition(all 0.3s ease-in-out);
		@include transform(scale(1.3));
	}

	&.mfp-bg {
		opacity: 0;
		@include transition(all 0.3s ease-out);
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			@include transform(scale(1));
		}
		&.mfp-bg {
			opacity: 0.95;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			@include transform(scale(1.3));
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}

	}

}

/**
* Simple fade transition
* ----------------------------------------------------------------------------
*/
.mfp-fade.mfp-bg {
	opacity: 0;
	@include transition(all 0.15s ease-out);
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	@include transition(all 0.15s ease-out);
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}
//rgba(69,90,235,0.95)