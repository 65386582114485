.home-main {
	.feature-destination {
		.separate {
			border-bottom: 1px solid $border-color;
		}

		.heading {
			padding-top: 85px;
		}

		.slick-arrow {
			top: calc(50% - 40px);

			i {
				font-size: 22px;
				font-weight: 400;
			}
		}

		.slick-dots {
			top: 104%;
		}
	}

	.our-directory {
		.container-1720 {
			padding: 0 30px;
		}

		.nav-link {
			font-size: 16px;
		}

		@media screen and (max-width: 1500px) {
			.tab-content {
				max-width: 1170px;
				margin: 0 auto;
			}
		}
		@include media-breakpoint-down(sm) {
			.slick-custom-nav {
				display: none !important;
			}
		}
	}
}

.home-main-intro {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	z-index: 1;
	padding-top: 210px;

	&::after {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background: linear-gradient(to bottom, transparent 50%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
	}

	.home-main-intro-container {
		position: relative;
		z-index: 3;
	}

	.heading {
		h1 {
			font-size: 90px;
			line-height: 0.93;
			letter-spacing: -0.05em;
			@include media-breakpoint-down(sm) {
				font-size: 60px;
			}
		}
	}

	.dropdown-menu {
		z-index: 9999 !important;
	}

	.listing-icon-box {
		padding-bottom: 55px;
	}
}

.home-main-how-it-work {
	max-width: 1370px;
	margin: 0 auto;
}

.home-main-testimonial {
	background-image: url("../../../../assets/image/other/main-background-testimonion.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
