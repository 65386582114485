.err-msg {
    margin-left: -25px;
}

.err-msg-mobile {
    margin-left: -11px;
}

.err-msg-font {
    font-size: 12px;
    color: #d32f2f;
}