.collapse-tabs {
	.card-header {
		border: 1px solid $border-color;

		&:hover, &:focus, &:active {
			background: transparent !important;
		}
	}

	.card {
		border: 0 !important;
	}

	@include media-breakpoint-down(xs) {
		.tab-pane {
			display: block;
			opacity: 1;
		}
		.card-body {
			border: 1px solid $border-color;
			border-top: 0;
		}
	}

	.card-header {
		.btn {
			color: $gray-600;
		}

		.btn:not(.collapsed) {
			color: $black;
		}
	}
}
