.post {
	@include d-flex();

	.feature-image {
		position: relative;
		height: 100%;

		.image-content {
			position: absolute;
			top: 15px;
			right: 15px;
		}

		.media {
			span {
				width: 40px;
				height: 40px;
				line-height: 40px;
				background: #000;
				color: #fff;
				font-size: 18px;
				text-align: center;
				border-radius: 2px;
			}
		}

		.box {
			margin-bottom: 0 !important;

		}

		.slick-arrows {
			position: absolute;
			bottom: 43px;
			left: 33px;

			.slick-arrow {
				width: 40px;
				height: 40px;
				line-height: 40px;
				background: #000;
				color: #fff;
				font-size: 18px;
				text-align: center;

				i {
					font-weight: 300;
				}
			}

			.slick-next {
				left: 45px;
			}

			.slick-disabled {
				background: #2f2f2f;
			}
		}
	}
}

.post-style-1, .post-style-2 {
	.feature-image {
		width: 200px;
		flex-shrink: 0;
		background-size: cover;
		background-position: center;
		height: auto;
		transition: .3s ease-in-out;
	}

	.post:hover {
		.feature-image {
			opacity: 1;
			animation: flash 1.5s;
		}
	}

	@media screen and (max-width: 576px) {
		.post {
			@include flex-wrap();
		}
		.feature-image {
			@include flex(1 1 100%);
			max-width: 100%;
			height: 0;
			padding-top: 75%;
		}

	}
}

.post-style-5 {
	.post {
		background: #f2f2f2;
	}

	.media {
		top: 20px !important;
		right: 25px !important;
	}

	.card {
		padding: 60px 40px 55px 55px;
		@media screen and (max-width: 400px) {
			padding: 30px;
		}
	}
}
