.toggle-icon {
	cursor: pointer;
	display: inline-block;
	width: 32px;
	height: 30px;
	position: relative;
	vertical-align: middle;
}

.toggle-icon > span {
	bottom: 0;
	display: block;
	height: 2px;
	left: 2px;
	right: 8px;
	margin: auto;
	position: absolute;
	top: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	background-color: currentColor;
}

.toggle-icon > span:after,
.toggle-icon > span:before {
	content: '';
	height: 2px;
	left: 0;
	margin: auto;
	position: absolute;
	right: -6px;

	-webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out, background 0.3s;
	-moz-transition: top 0.3s ease-in-out, -moz-transform 0.3s ease-in-out, background 0.3s;
	transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, background 0.3s;

	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	background-color: currentColor;
}

.toggle-icon > span:before {
	top: 8px;
}

.toggle-icon > span:after {
	top: -8px;
}

.toggle-icon:hover {
	color: #e4573d;
}

.toggle-icon.in > span {
	background-color: transparent !important;
}

.toggle-icon.in > span:after,
.toggle-icon.in > span:before {
	-webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s 0.5s ease-in-out, background 0.3s;
	-moz-transition: top 0.3s ease-in-out, -moz-transform 0.3s 0.5s ease-in-out, background 0.3s;
	transition: top 0.3s ease-in-out, transform 0.3s 0.5s ease-in-out, background 0.3s;

	top: 0;
}

.toggle-icon.in > span:after {
	-webkit-transform: rotate3d(0, 0, 1, -45deg);
	-moz-transform: rotate3d(0, 0, 1, -45deg);
	-ms-transform: rotate3d(0, 0, 1, -45deg);
	-o-transform: rotate3d(0, 0, 1, -45deg);
	transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-icon.in > span:before {
	-webkit-transform: rotate3d(0, 0, 1, 45deg);
	-moz-transform: rotate3d(0, 0, 1, 45deg);
	-ms-transform: rotate3d(0, 0, 1, 45deg);
	-o-transform: rotate3d(0, 0, 1, 45deg);
	transform: rotate3d(0, 0, 1, 45deg);
}
