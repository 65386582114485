.page-about {
	.banner {
		// background-image: url("../../../../assets/image/banner/about-banner.jpg");

		.heading {
			max-width: 830px;
			margin: 0 auto;
		}
	}

	.banner-content {
		padding-top: 320px;
		padding-bottom: 288px;

	}

	.about-intro {

		.jumbotron {
			max-width: 830px;
			margin: 0 auto 80px;
		}
	}

	.counter {
		width: auto;
	}
}
