body {
    margin: 0px;
  }
  
  .leaflet-bar,
  .leaflet-bar:hover {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
  }
  
  .leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .leaflet-bar a:last-child {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }
  
  .leaflet-bar a {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
  }
  
  .leaflet-bar a.leaflet-control-zoom-in,
  .leaflet-bar a.leaflet-control-zoom-out {
    font: bold 18px "Lucida Console", Monaco, monospace;
    text-indent: 1px;
  }
  
  .leaflet-div-icon {
    background: none;
    border: none;
  }
  