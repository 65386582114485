.page-how-it-work {
	.page-description {
		max-width: 630px;
		margin: 0 auto 80px;
	}

	.how-it-work {
		max-width: 830px;
		margin: 0 auto 90px;
	}

	.add-listing {
		max-width: 630px;
		margin: 0 auto;
	}

}