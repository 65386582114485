.image-box {
	overflow: hidden;


	.image {
		z-index: 1;


		&::after {
			content: '';
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
		}

	}

	.content-box {
		left: 0;
		bottom: 0;
		top: auto;
		z-index: 3;
		line-height: 1.2;
	}

	&.overlay-lighter {
		.image::after {
			background: linear-gradient(to bottom, transparent 80%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1));
		}
	}

	&.no-overlay {
		.image::after {
			display: none;
		}
	}
}

.image-box-center {

	.image {
		min-height: 186px;
	}

	.card-body {
		.card-title {
			font-size: 26px;
			line-height: 0.92;
			letter-spacing: -0.05em;
		}
	}
}

.image-box-center-style-02 {
	.card-header {
		min-height: 139px;
	}
}


.image-box-style-card {
	.card-body {
		padding: 14.5px 18px;
	}
}

.listing-image-card {
	&:not(:last-child) {
		margin-bottom: 65px;
	}

	.heading {
		@media screen and (max-width: 400px) {
			max-width: 200px;
		}
	}

	.slick-arrows {
		top: -58px !important;
		right: 15px !important;
	}
}

.hover-scale {
	overflow: hidden;

	.image {
		transition: .3s ease-in-out;
		transform: scale(1);
	}

	&:hover {
		.image {
			transform: scale(1.08);
		}
	}
}

.hover-flash {
	&:hover {
		.image {
			opacity: 1;
			animation: flash 1.5s;
		}
	}

}

@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}