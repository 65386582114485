.main-footer {
	padding-bottom: 55px;
}

.gtf-back-to-top {
	visibility: hidden;

	&.in {
		visibility: visible;
	}
}

.footer-first {
	padding-top: 38px;
	padding-bottom: 38px;

	.footer-first-container {
		@include media-breakpoint-down(md) {
			@include flex-wrap();
			.download {
				margin-left: 0 !important;
				margin-top: 30px;
			}
			.fact {
				@include flex-wrap();
			}
		}

	}

	.download {
		.item {
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}

}


.footer-second {
	.form-newsletter {
		.btn, .form-control {
			height: 47px;
		}

		.input-group-append {
			display: inline-block;
		}
	}

	.region {

		.select-custom {
			width: 200px;

			&::after {
				font-size: 12px;
			}
		}

		select {
			border-color: transparent;
		}
	}
}


.main-footer {
	&.bg-lighter-dark {
		color: $gray-600;

		.footer-first {
			border-bottom: 1px solid #404040;
		}

		.input-group {
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
			background: $secondary !important;
		}

		.form-control {
			border: none;
			color: #cccccc;

			&:focus {
				background: $secondary
			}

			&::placeholder {
				color: #cccccc;
			}

		}

		.social-icon {
			a {
				&:hover {
					color: #fff;
				}

			}
		}

		.back-top {
			a {
				color: #f5f5f5;
			}
		}

	}
}

.main-footer-style-01 {
	.footer-second {
		.input-group-append {
			&:hover, &:focus {
				background: $white;
			}
		}
	}

	.social-icon {
		li {
			i {
				font-size: 16px;
			}

			.icon {
				font-size: 16px;
			}
		}
	}

	.back-top {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		@include media-breakpoint-down(sm) {
			left: 0;
			right: auto;
			bottom: -30px;
			top: auto;
		}

		a {
			color: $secondary;
		}

		i:not(:last-child) {
			margin-right: 10px;
		}

		&.in {
			visibility: visible;
		}
	}

}

.main-footer-style-02 {
	position: relative;

	.footer-last-container {
		@include media-breakpoint-down(lg) {
			@include flex-wrap();
			.social-icon {
				flex: 1 1 100%;
			}
		}
	}

	.back-top {
		font-size: 30px;
		line-height: 1;
		display: inline-block;
		position: fixed;
		right: 30px;
		bottom: 30px;
		z-index: 99999;
		top: auto;
		transform: none;

		a {
			color: $black;
		}

		@media screen and (max-width: 1500px) {
			right: 10px;
		}
	}
}

