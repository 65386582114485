.coming-soon {
	background-image: url("../../../../assets/image/other/comming-soon.jpg");
	background-size: cover;
	background-position: center;

	.main-header {
		padding-top: 82px;
	}

	.wrapper-content {
		max-width: 630px;
		margin: 0 auto;
	}

	.main-footer {
		padding-bottom: 247px;

		.social-icon {
			li:not(:last-child) {
				margin-right: 33px;
			}

			a {
				color: #ccc;
				font-size: 16px;

				&:hover {
					color: $black;
				}
			}
		}
	}
}