@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $font-size, $value in $font-sizes {
			.font-size#{$infix}-#{$font-size} {
				font-size: $value !important;
			}
		}
	}
}
