.document {
	.doc-sidebar {
		scrollbar-color: $secondary #cccccc;

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 4px #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $gray-600;
			outline: 1px solid $gray-600;
		}

	}

	.sidebar {
		font-size: $font-size-base;

		.sidebar-menu li {
			&.active {
				a {
					color: $black;
					font-weight: $font-weight-medium;
				}
			}
		}
	}

	.scroll-spy {
		top: 6rem;
		overflow-y: auto;
		font-size: $font-size-base;

		.list-group-item {
			&.active {
				color: $black;
				font-weight: $font-weight-medium;
			}
		}
	}

	.table {
		th {
			font-weight: $font-weight-medium;
		}
	}

	a:hover {
		text-decoration: none;
	}
}

.callout {
	border-left-width: 10px;
	border-left-style: solid;
	padding: 1.5rem;
	margin-bottom: 1.25rem;
}

@each $color, $value in $theme-colors {
	.callout-#{$color} {
		border-color: rgba($value, 0.25);
		background-color: rgba($value, 0.05);
	}
}

code[class*="language-"] {
	tab-size: 0;
	-moz-tab-size: 0;
	margin: 0;
}

pre[class*="language-"] {
	margin-bottom: 20px;
	margin-top: 0;
}

.bd-example {
	.border, .border-top, .border-right, .border-bottom, .border-left {
		display: inline-block;
		width: 5rem;
		height: 5rem;
		background: $white;
	}
}

.starter, .document {
	.doc-sidebar {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		height: 100vh;
		overflow-y: auto;
		scrollbar-width: thin;


		&::-webkit-scrollbar {
			width: 4px;
		}

		@include media-breakpoint-down(sm) {
			height: auto;
			flex: 0 1 100%;
			position: static;
			overflow-y: unset;
		}
	}

	.sidebar {
		max-height: 100vh;
		width: 100%;

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
		}


		.navbar-collapse {
			flex-grow: 0;
			overflow-y: auto;
		}
	}

	.tab-pane .code-toolbar {
		max-height: 700px;
		overflow: auto;
	}

	.search-result {
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
		background: $white;
		width: 100%;
		z-index: 9999;
		box-shadow: $box-shadow;

		.list-group-item {
			&:not(:last-child) {
				border-bottom: 1px solid $border-color;
			}

			&:hover, &.active {
				background: $gray-200;
				color: $dark !important;
			}
		}

		&.show {
			display: block;
		}
	}

	.doc-heading {
		.anchorjs-link {
			transition: all 0.2s;
			text-decoration: none;
			font-size: 80%;
			visibility: hidden;
		}

		&:hover {
			.anchorjs-link {
				visibility: visible;
			}
		}
	}
}

.starter {
	font-size: 1rem;

	.doc-sidebar {
		scrollbar-color: $secondary #cccccc;

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 4px $lighter-dark;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $secondary;
			outline: 1px solid $secondary;
		}

		.navbar-collapse .list-group-item {
			font-size: $font-size-base;

			&.active {
				a {
					color: $white;
				}
			}
		}

		.toogle {
			&::after {
				display: inline-block;
				content: "\f078";
				font-family: "Font Awesome 5 Pro";
				font-size: 10px;
				margin-left: 5px;
				transition: all 0.3s;
			}

			&.open {
				&::after {
					-moz-transform: rotate(-180deg);
					-webkit-transform: rotate(-180deg);
					transform: rotate(-180deg);
				}
			}
		}


	}


}

.demo-header {
	pre[class*="language-"] {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}